import * as React from 'react';
import { useState, useContext , useEffect } from 'react'; 
import { DataGrid,  GridColDef, GridRowParams,  } from '@mui/x-data-grid';
import * as Type from '../../models/Types';
import { ExpertAdviceDto, ExpertAdviceContextType } from '../../models/Types'; 
import { DialogFunctionContext } from '../../context/DialogFunctionContext';
import { ExpertAdviceModal } from './ExpertAdviceModal'; 
import { ExpertAdviceContext } from '../../context/ExpertAdvice/ExpertAdviceContext' ; 
import { SnackbarContext } from '../../context/Snackbar/SnackbarContext'; 
import { ExpertAdviceCompleteModal } from './ExpertAdviceCompleteModal' ; 

interface TemplatePageProps {
    status : number,
    list : ExpertAdviceDto[]
    
}


//move this to common file if there is more stuff like this

export const ExpertAdivceList = ({status, list} : TemplatePageProps ) => {
 
    const [showDialog, setShowDialog] = useState(false); 
    const [ adviceItem , setAdviceItem] = useState<Type.ExpertAdviceDto>(); //default, undefined as default value 
  //  const [ _showSnackbar, setShowSnackbar ] = useState(false); 
  const { removeSnackbar }  = useContext(SnackbarContext)  as Type.SnackbarContextType ;   

   //console.log('list view', list); 
       
       const columns: GridColDef[] = [
         { field: 'id', headerName: 'Id', width: 50 },
         { field: 'submitDate', headerName: 'Submit Date', width: 100,  type: 'date',
           valueGetter: (params) => {
             //if(!params.value){
               return new Date(params.value)
             //}
           },
          // valueFormatter: (params) => { return params.value }, 
        },
        { field: 'title', headerName: 'Title', width: 200},
         { field: 'knObjectType', headerName: 'KN Object', width: 150},
         { field: 'userAdvice', headerName: 'Expert Advice', width: 230 },
         { field: 'displayInUI', headerName: 'UI Display', width: 100,
         valueGetter: (params) => {
            //if(!params.value){
              return  params.value === null ? 'false' : params.value.toString();
            //}
          } },
         { field: 'userNetId', headerName: 'User NetId', width: 80 },
         //{ field: 'test', headerName: 'hidden', width: 100, hideable: true}
       ];
 

    //   useEffect(() => {
    //    setShowSnackbar(showSnackbar); 
    //   }, [showSnackbar, adviceItem]) //when showSnackbar changes on each item, display snackbar
  
       

 
      const handleRowClick = (params: GridRowParams ) =>{     
       //open modal      
       setAdviceItem(params.row)
       setShowDialog(true);    

       //reset the snackbar false
       removeSnackbar();      
      } 
 
      const handleCloseDialog = (showDialog : boolean) => {
       setShowDialog(showDialog); //closing dialog //which is value would be false always since it is closing it from child 
      }

    /*   const handleSnackbarClose = () =>{
        setShowSnackbar(false); 
      } 
    */

      
     return (       
         <>
            <div style={{  width: '100%' }}>
                  <DataGrid rows={list} columns={columns} density='compact' 
                    
                     initialState={{
                       pagination: {
                         paginationModel: {
                           pageSize: 10,
                         },
                       },
                     }}
                     pageSizeOptions={[10,20,30,40,50,]}
                      onRowClick={handleRowClick}
                      sx={{"& .MuiDataGrid-row:hover" : {
                        cursor: 'pointer'                     
                    }}}
                
                  />
             </div>
             <DialogFunctionContext.Provider value={{showDialog, closeDialog: handleCloseDialog}} >
                
                {(status === 1 || status === 2 ) && 
                  <ExpertAdviceModal show={showDialog} adviceItem={adviceItem} dialogType={status ===1 ? 'To Do' : status === 2 ? 'In Progress' : 'Complete'} />
                }
                {  status  === 3 &&
                 <ExpertAdviceCompleteModal  show={showDialog}  id={adviceItem?.id} dialogType='Complete' requestNumber={new Date().getMilliseconds()} />
                 }
                 
                  

             </DialogFunctionContext.Provider>   


                  {/* since modal closes too quick, not able to see the snackbar. moving here */}      
                 
         </>
 
     );
    

}