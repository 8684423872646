
export const feedbackReducer = (state : any, action : any) =>{

    switch(action.type){

        case 'GET_FEEDBACK' :
            return {
                ...state,
                feedbacklist: action.payload,
                isLoading: false,
                hasLoadingError : action.hasLoadingError
            }
        case 'UPDATE_FEEDBACK' :
            return {
                ...state,
                showsnackbar: true,
                hasUpdateError : action.hasUpdateError,
                feedbacklist: action.payload
            }

        case 'SET_SNACKBAR' :
            return {
                ...state,
                showsnackbar: false        //resetting         
            }
        default:
            return state;
    }
}

