import * as React from 'react';
import { createContext , useReducer} from 'react';
import { ExpertAdviceContextType, ExpertAdviceDto } from '../../models/Types';
import {  FETCH_DATA_ERROR, STATUS_FAILED, STATUS_SUCCESSFUL } from '../../constants/constants';
import * as adviceApi from '../../api/ExpertAdviceApi'; 
import { expertAdviceReducer } from './ExpertAdviceReducer'; 
/*
*/

export const ExpertAdviceContext = createContext<ExpertAdviceContextType | null>(null);

export const ExpertAdviceProvider : React.FC<{children: React.ReactNode}> = ({ children }) => {

 const initialState = {
  advicelist: [] as ExpertAdviceDto[] ,
  isLoading : true,
  hasLoadingError : false,
  hasUpdateError : false, 
  showsnackbar : false
 }

 const [state, dispatch] = useReducer(expertAdviceReducer, initialState);
 
  const fetchExpertAdvice = async () => {
    const response  = await adviceApi.getAllExpertAdvice();     

    dispatch({
      type : 'GET_EXPERTADVICE',
      payload: response === FETCH_DATA_ERROR ? [] as ExpertAdviceDto[] : response,
      hasLoadingError : response === FETCH_DATA_ERROR ? true : false
     })

  };
   
  /* not used. api call directly from component. to catch response result to set snackbar*/
  // Update feedback item
  const updateAdvice = async (updItem: ExpertAdviceDto ) => {
  
    const response = await adviceApi.updateAdvice(updItem);      
   
    dispatch({
      type : 'UPDATE_EXPERTADVICE',
      hasUpdateError : response === STATUS_SUCCESSFUL ? false : true,     
      payload: response === STATUS_SUCCESSFUL ? state.advicelist.map((item : ExpertAdviceDto) => (item.id === updItem.id ? updItem : item)) : state.advicelist, //update list when it is successful       

     })
  } 

  const resetSnackbar = async ( ) => {
         
    dispatch({
      type : 'SET_SNACKBAR'     
     })
  } 

 /* */ 

  return (
    <ExpertAdviceContext.Provider
      value={{
        advicelist: state.advicelist,
        isLoading : state.isLoading,       
        hasLoadingError: state.hasLoadingError,
        updateAdvice,
        fetchExpertAdvice,        
      }}
    >
      {children}
    </ExpertAdviceContext.Provider>
  )
}

export default ExpertAdviceProvider
