import * as React from 'react';
import { ExpertAdviceProvider } from '../../context/ExpertAdvice/ExpertAdviceContext';
import { ExpertAdviceCompletePageWrapper } from './ExpertAdviceCompletePageWrapper'; 
import { SnackbarProvider } from '../../context/Snackbar/SnackbarContext' ; 

interface TemplatePageProps {
    
}


//move this to common file if there is more stuff like this

export const ExpertAdviceCompletePage = ({} : TemplatePageProps ) => {
     
    return (       
        <>
           <ExpertAdviceProvider>  
              <SnackbarProvider>
                  <ExpertAdviceCompletePageWrapper />
               </SnackbarProvider>
          </ExpertAdviceProvider>  
        </>

    );
    

}