import * as React from 'react';
import { useState } from 'react'; 
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';


interface TemplatePageProps {
    
}


//move this to common file if there is more stuff like this

export const SideNav = ({} : TemplatePageProps ) => {
 
    console.log(window.location.pathname); 
    
  //  const [activeKey , setActiveKey] = useState('')

 //   const handleClick = (val:string) => {

  //      setActiveKey(val);
  //  }
 
    return (    
        <Container >

         
                <Nav  variant="pills" className="flex-column" activeKey={window.location.pathname} >
                        <Nav.Item>
                            <Nav.Link eventKey="/home" href="/home" >Home</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="/search"  href='/search' >Rebase Search</Nav.Link>
                        </Nav.Item>   
                        <Nav.Item>
                            <Nav.Link eventKey="/feedback"  href='/feedback' >Manage User Feedback</Nav.Link>
                        </Nav.Item> 
                        <Nav.Item>
                            <Nav.Link eventKey="/advice"  href='/advice' >Manage Expert Advice</Nav.Link>
                        </Nav.Item> 
                        <hr />
                        <p>Completed</p>
                        <Nav.Item>
                            <Nav.Link eventKey="/feedback_completed" href="/feedback_completed">User Feedback</Nav.Link>
                        </Nav.Item>  
                        <Nav.Item>
                            <Nav.Link eventKey="/advice_completed" href="/advice_completed">Expert Advice</Nav.Link>
                        </Nav.Item>                   
                </Nav>

        </Container> 

    );
    

}