import * as React from 'react';
import { useState, ChangeEvent } from 'react';
import Grid from '@mui/material/Grid';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField  from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { UserFeedbackList } from './UserFeedbackList';
import * as feedbackApi from '../../api/UserFeedbackApi'; 
import { UserFeedbackDto } from '../../models/Types'; 
import {  FETCH_DATA_ERROR, } from '../../constants/constants';
import { UserFeedbackProvider } from '../../context/Feedback/FeedbackContext' ; 
import { SnackbarProvider } from '../../context/Snackbar/SnackbarContext' ; 

interface TemplatePageProps {
    
}


//move this to common file if there is more stuff like this

export const UserFeedbackCompletePage = ({} : TemplatePageProps ) => {
  
    const [duration, setDuration] = useState('0');
    const [knobject, setKnobject ] = useState('select');
    const [userNetid, setUserNetid]= useState(''); 
    const [searchResult, setSearchResult] = useState<UserFeedbackDto[]>([]);


    const handleDurationChange = (event: SelectChangeEvent<typeof duration>) => {
         setDuration(event.target.value);
    };

    const handleKnObjectChange = (event: SelectChangeEvent<typeof duration>) => {
        setKnobject(event.target.value);
    };

    const handleNetIdChange = (event:ChangeEvent<HTMLInputElement>) => {
        setUserNetid(event.target.value);
       
    };
        
    const handleSearch = async () =>{
        //rest grid data
        setSearchResult([]);
       // setDuration('0');
       // setKnobject('select');
       // setUserNetid('');

     //get data here //param are optional 
    //  if(duration !== '0' && knobject !== 'select'){
        var response = await feedbackApi.searchFeedback(duration, knobject, userNetid)
        if (response !== FETCH_DATA_ERROR ){
            console.log('response 2', response); 
            setSearchResult(response);
        } 
    // }
  

    }

    return (       
      <>
       <UserFeedbackProvider>  
        <SnackbarProvider>
        <h4>Search Completed User Feedback</h4>
           <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
                <Grid item xs={3}>
                Submitted:<br />
                <Select                  
                  //  defaultValue={duration}
                    label="Submitted"
                     onChange={handleDurationChange}
                     value={duration}
                    >  
                        <MenuItem value="0">Select</MenuItem>     
                        <MenuItem value={"1"}>1 month</MenuItem>
                        <MenuItem value={"2"}>2 months</MenuItem>
                        <MenuItem value={"6"}>6 months</MenuItem>
                        <MenuItem value={"12"}>1 year</MenuItem>
                    </Select>
                </Grid>
                <Grid item xs={3}>
                KN Object Type:<br />
                     <Select                       
                        
                        label="KN Object Type"
                         onChange={handleKnObjectChange}
                         value={knobject}
                        >
                        <MenuItem value={'select'}>Select</MenuItem>     
                        <MenuItem value={'Term'}>Business Term</MenuItem>                     
                        <MenuItem value={'Column'}>Column</MenuItem>
                        <MenuItem value={'Cube'}>Cube</MenuItem>   
                        <MenuItem value={'Attribute'}>Cube Attribute</MenuItem>
                        <MenuItem value={'Measure'}>Cube Measure</MenuItem>                    
                        <MenuItem value={'Dashboard'}>Dashboard</MenuItem>
                        <MenuItem value={'Diagram'}>Diagram</MenuItem>
                        
                                <MenuItem value={'Report'}>Report</MenuItem>
                                <MenuItem value={'Search'}>Search</MenuItem>
                        <MenuItem value={'Table'}>Table</MenuItem>                       
                        <MenuItem value={'WSResource'}>WSResource</MenuItem>
                                <MenuItem value={'WSField'}>WSField</MenuItem>
                               
                       
                    </Select>
                </Grid>
                <Grid item xs={3}>
                User NetId:<br />
                     <TextField  variant="outlined" onChange={handleNetIdChange} value={userNetid} />
                </Grid>
                <Grid item xs={3} sx={{paddingTop: '20px',alignContent: 'right'}}>
                    <br />
                     <Button variant="contained" onClick={handleSearch}>Search</Button>
                </Grid>
          </Grid>
          <div style={{height: '20px'}}></div>
          <h5>Results</h5> 
         
          {/* only load UserFeedbackList if there is data ==> if not , it would be blank page */}
           {searchResult.length > 0 &&
                <UserFeedbackList status={3} feedbacklist={searchResult} />
           }
            {searchResult.length === 0 &&
                <p> No result found</p>
             }
        </SnackbarProvider>
       </UserFeedbackProvider>  
       
     </>

    );
    

}