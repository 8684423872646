

export const snackbarReducer = (state : any, action : any) => {
  
    switch (action.type) {
      case 'SET_SNACKBAR':
        return {
            ...state,
            showSnackbar : true,
            msg : action.payload.msg,
            bgColor: action.payload.bgColor
        } 
      case 'REMOVE_SNACKBAR':
        return {
            ...state,
            showSnackbar : false,          
        } 

     
      default:
        return state
    }
  }
  
  export default snackbarReducer
  