import * as React from 'react';
import { useEffect, useState, useContext } from 'react';
import { UserFeedbackList } from './UserFeedbackList'; 
import { UserFeedbackDto, UserFeedbackContextType, SnackbarContextType } from '../../models/Types'; 
import {  FeedbackContext } from '../../context/Feedback/FeedbackContext' ; 
import { SnackbarContext  } from '../../context/Snackbar/SnackbarContext'; 

import Snackbar from '@mui/material/Snackbar';
// import { Link } from 'react-router-dom'; 
//import {  FETCH_DATA_ERROR} from '../../constants/constants';

interface TemplatePageProps {
    
}


//move this to common file if there is more stuff like this

export const UserFeedbackWrapper = ({} : TemplatePageProps ) => {
   // console.log('b');

    const { feedbacklist,   hasLoadingError, isLoading,  fetchFeedback  } = useContext(FeedbackContext)  as UserFeedbackContextType;
    const { showSnackbar, msg, bgColor }  = useContext(SnackbarContext)  as SnackbarContextType ; 
    const [ todoFeedback, setTodoFeedback ] = useState<UserFeedbackDto[]>([]); 
    const [ inProgressFeedback, setInProgressFeedback ] = useState<UserFeedbackDto[]>([]); 
   //  const [ isLoading, setIsLoading] = useState(true);
     const [ _showSnackbar, setShowSnackbar ] = useState(showSnackbar);  //todo 
   /*
   */ 
    
   useEffect( () => {     
   
     fetchFeedback();
   }, [] ); //on component load, fetch feedback 
  
// 
  
   /**/
  useEffect(() => { 
    if(!hasLoadingError){
        //when list change, bind the data
        setTodoFeedback(feedbacklist !== null? feedbacklist.filter(s => s.status === 1) : []);
        setInProgressFeedback(feedbacklist !== null ? feedbacklist.filter(s => s.status === 2) : []);   
    }  

    // console.log('feedbacklist', feedbacklist);
  }, [feedbacklist,  hasLoadingError ] ); //on load, feedbacklist is [] then filled in

  //
  useEffect(() => {      
       setShowSnackbar(showSnackbar);
      //  console.log('show snackbar', showSnackbar); 
      }, [showSnackbar] ); //when modal dialog save the status, regroup the list
 
  /*  
 */
     
    //when modal update the data, update this feedbacks itself so don't need to fetch the data again 
    const handleSnackbarClose = () =>{
        setShowSnackbar(false); 
      }

    return (       
          
      <>
            <h4>Manage User Feedback</h4>

            {isLoading && <p>Loading...</p>}   
         
          {!isLoading && hasLoadingError && <p>Error while loading feedback...ask moonk2</p>}

            {!isLoading && !hasLoadingError && 
            <>
                
                    <h5>To Do</h5>
                    {todoFeedback.length > 0  && 
                    <UserFeedbackList status={1} feedbacklist = {todoFeedback}></UserFeedbackList>
                    }
                    {todoFeedback.length  === 0 && 
                        <p>No To Do item found</p>
                    }

                    <div style={{height: '20px'}}></div>
                    
                    <h5>In Progress</h5>
                    {inProgressFeedback.length > 0 &&
                    <UserFeedbackList status={2}  feedbacklist = {inProgressFeedback}></UserFeedbackList>
                    }
                    {inProgressFeedback.length === 0  && 
                        <p>No In Progress item found</p>
                    }   

                    <div style={{height: '20px'}}></div>
                    
                    
                
            </>}    {/* */}

            {/* snackbar - confirmation message */}
           <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={_showSnackbar}
                onClose={handleSnackbarClose}
                message={msg}
                autoHideDuration={5000}     
               ContentProps={{sx: {background : bgColor }
            }}   
            />           
        

    </>
              
    

    );
    

}