import * as React from 'react';
import { Navbar, NavbarBrand} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';

interface TemplatePageProps {
    
}


//move this to common file if there is more stuff like this

export const Header = ({} : TemplatePageProps ) => {
 
 
    return (       
        <>
          
          <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" >
                 <NavbarBrand style={{paddingLeft: '30px'}}>KN.Admin</NavbarBrand>
                
                </Navbar>
         </header>
        </>

    );
    

}