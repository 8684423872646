import * as React from 'react';
import { useState, useEffect, useContext, ChangeEvent, useRef } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
//import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {  Close, } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button'; 
//import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { DialogFunctionContext } from '../../context/DialogFunctionContext';
import { ExpertAdviceContext } from '../../context/ExpertAdvice/ExpertAdviceContext' ; 
import {  ExpertAdviceDto, SnackbarContextType, ExpertAdviceContextType } from '../../models/Types'; 
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import  * as adviceApi from '../../api/ExpertAdviceApi' ; 
import { SnackbarContext } from '../../context/Snackbar/SnackbarContext'; 
import Editor ,  { ContentEditableEvent,  } from 'react-simple-wysiwyg';

interface Props {
    adviceItem :  ExpertAdviceDto | undefined ,
    show: boolean,
    dialogType : string,
   // status : number
}


//move this to common file if there is more stuff like this

export const ExpertAdviceModal = ({ adviceItem, show, dialogType} : Props ) => {
  
   // const adviceItemCurrent = useRef(adviceItem);

    //   console.log('first',  adviceItem === undefined ? 'false' : adviceItem.displayInUI !== null ? adviceItem.displayInUI.toString() : 'false' );
   //feedback could be null
    // const[ adviceItem, setAdviceItemCurrent] = useState(_adviceItem); 
    const dialogContext = useContext(DialogFunctionContext); 
    const [showDialog, setShowDialog] = useState(show);
    const {  fetchExpertAdvice  } = useContext(ExpertAdviceContext) as ExpertAdviceContextType;
    const [ displayInUi, setDisplayInUi ] = useState( adviceItem === undefined ? 'false' : adviceItem.displayInUI !== null ? adviceItem.displayInUI.toString() : 'false' );
    const [ _displayName, setDisplayName ] = useState( adviceItem === undefined ? 'false' : adviceItem.isAnonymous !== null ? adviceItem.isAnonymous.toString() : 'false' ); //remember that this value is IsAnonymous and question are opposite value
    const [ advice , setAdvice ] = useState(adviceItem === undefined ? '' : adviceItem.approvedAdvice );    
    const { setSnackbar } = useContext(SnackbarContext) as SnackbarContextType;
    //capturing original value for search result detail case
    //when x out value still remain bug
    //since search case, by closing it doesn't reload dataset
 //   const prevDisplayName = usePrevious(adviceItem === undefined ? 'false' : adviceItem.isAnonymous !== null ? adviceItem.isAnonymous.toString() : 'false');
 //   const prevUIName =  usePrevious(adviceItem === undefined ? 'false' : adviceItem.displayInUI !== null ? adviceItem.displayInUI.toString() : 'false');
  //  const prevApprovedAdvice = usePrevious(adviceItem === undefined ? '' : adviceItem.approvedAdvice ); 
  


    useEffect(() =>{
   //when each time show value change, 
    setShowDialog(show);
  }, [show]);

 /*  useEffect(() =>{

      setAdviceItemCurrent(_adviceItem); 

      }, [_adviceItem]); 
*/

  useEffect(() =>{
    // console.log('a', adviceItem);
    //on item load, for some reason, this value is always false
    //so bind it again to get the acutall val
    //if prev item  is same then this wouldn't get called 
     setAdvice(adviceItem === undefined ? '' : adviceItem.approvedAdvice);
     setDisplayInUi( adviceItem === undefined ? 'false' : adviceItem.displayInUI !== null ? adviceItem.displayInUI.toString() : 'false');
     setDisplayName( adviceItem === undefined ? 'false' : adviceItem.isAnonymous !== null ? adviceItem.isAnonymous.toString() : 'false'  )
   }, [adviceItem]);

  //console.log('isAnonymous', _displayName) ; 
   /*
   
   console.log('displayInUi', displayInUi); 
   console.log('displayInUI', adviceItem?.displayInUI); */
  // console.log('approved advice', adviceItem?.approvedAdvice); 

  const handleApprovedAdviceChange = (event:ContentEditableEvent) =>{
    setAdvice(event.target.value);
  }

  const handleInprogress = async () => {
  // console.log('advice', advice) ;

  /*  */  
   if(adviceItem !== undefined){ 
        //save data =>   
        //    console.log('_displayName', _displayName); //this_displayName is switched from radio button value 
        const response = await adviceApi.updateAdvice({ ...adviceItem, approvedAdvice: advice === null ? '' : advice, status: 2, isAnonymous: _displayName ==='true' ? true : false, displayInUI: displayInUi === 'true' ?  true : false   });     
  
        //show snackbar     
       if(response === 'Failed'){
         setSnackbar('Update Failed.', 'red');
        }else{
         setSnackbar('Update Successful!', 'green');
        }
      
        //refetch updated data
        fetchExpertAdvice();   

      //close dialog
      setShowDialog(false);  
      dialogContext.closeDialog(false);  
    }     
  }
  
 
  const handleComplete = async () => {
  /*  */  
   if(adviceItem !== undefined){ 
   //  console.log('handle complete', advice); 
       
    //save data =>   //trigger regroup feedbacklist ==> handled in feedbackcontext
        const response = await adviceApi.updateAdvice({ ...adviceItem, approvedAdvice:  advice === null ? '' : advice, status: 3, displayInUI: displayInUi === 'true' ?  true : false, isAnonymous: _displayName ==='true' ? true : false  });   //value is already switched from radio button display name  
  
        //show snackbar     
       if(response === 'Failed'){
         setSnackbar('Update Failed.', 'red');
        }else{
         setSnackbar('Update Successful!', 'green');
        }
      
        //refetch updated data
        fetchExpertAdvice();   

      //close dialog
      setShowDialog(false);  
      dialogContext.closeDialog(false);  
    }   
 }

 const handleDisplayInUIChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  var val = (event.target as HTMLInputElement).value; 
    setDisplayInUi(val);
 }
 const handleIsAnonymousChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    var val = (event.target as HTMLInputElement).value; 
   
      setDisplayName(val);
   }
 
  
 const handleModalClose = () =>{

    //refetch updated data ==> preventing radio selection remembering last state 
     fetchExpertAdvice();   
     //need to send message to search to reload...

     setShowDialog(false); 
     dialogContext.closeDialog(false);     
 }
        
 /*
 function usePrevious(value :any) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    },[value]);
    return ref.current;
 }   */

    return (       
        <>
         

          <Dialog
             open={showDialog}
             onClose={(event, reason) => {
                if(reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                    // Set 'open' to false, however you would do that with your particular code.
                    setShowDialog(false);
                    dialogContext.closeDialog(false); 
                    //reset the hasUpdateError
                }
            }}
           
            fullWidth={true}              
            disableEscapeKeyDown
            maxWidth={'md'}
            >
          
            {adviceItem !== undefined && 
            <>  
                <DialogTitle sx={{ m: 0, p: 1 , borderBottom: 'solid 1px #ccc'}}>                    
                    <> Edit: {adviceItem?.title} ({adviceItem.id})</>
                    <IconButton sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }} onClick={handleModalClose}>
                                <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{marginTop: 1}}> 
                   
                <table cellPadding={0} cellSpacing={0}>
                   <tbody>
                   <tr>
                        <td style={{verticalAlign: 'top', width: '150px'}}> Current State</td>                        
                        <td style={{verticalAlign: 'top'}}><p>{dialogType}</p>   </td>
                    </tr>
                    <tr>
                        <td style={{verticalAlign: 'top', width: '150px'}}> <b>Submit Date</b></td>                        
                        <td style={{verticalAlign: 'top'}}><p>{adviceItem.submitDate !== null ? adviceItem.submitDate : 'n/a'}</p>   </td>
                    </tr>
                    <tr>
                        <td style={{verticalAlign: 'top', width: '150px'}}> KN Page Title</td>                        
                        <td style={{verticalAlign: 'top'}}><p>{adviceItem.title}</p>   </td>
                    </tr>
                    <tr>
                        <td style={{verticalAlign: 'top'}}> KN Object</td>                        
                        <td><p>{adviceItem.knObjectType}</p>   </td>
                    </tr>
                    <tr>
                        <td style={{verticalAlign: 'top'}}>Page Url</td>                        
                        <td><p><a href={adviceItem.knPageUrl} target='_blank'>{adviceItem.knPageUrl}</a></p>   </td>
                    </tr>
                    <tr>
                        <td style={{verticalAlign: 'top'}}>KN UID</td>                        
                        <td><p>{adviceItem.knUid}</p>   </td>
                    </tr>
                    <tr>
                        <td style={{verticalAlign: 'top'}}>User Info</td>                        
                        <td><p>{adviceItem.userName} ({adviceItem.userNetId}@uw.edu)</p>      </td>
                    </tr>
                    <tr>
                        <td ><b>Display Name?</b></td>                        
                        <td style={{verticalAlign: 'top'}}>
                            <RadioGroup row
                                value={_displayName}
                                onChange={handleIsAnonymousChange}                           
                            >
                                <FormControlLabel value={'false'} control={<Radio />}  label="Yes" /> {/*since DB isAnoynonus value is oposite of question 'display name' */}
                                <FormControlLabel value={'true'} control={<Radio />} label="No" />                            
                            </RadioGroup>  
                       </td>
                    </tr>
                    <tr>
                        <td> <b>Approve for UI?</b></td>                        
                        <td> 
                            
                        <RadioGroup row
                            value={displayInUi}
                            onChange={handleDisplayInUIChange}                           
                        >
                            <FormControlLabel value={'true'} control={<Radio />}  label="Yes" />
                            <FormControlLabel value={'false'} control={<Radio />} label="No" />                            
                        </RadioGroup>
                           
                        </td>
                    </tr>
                    <tr>
                        <td style={{verticalAlign: 'top',  marginTop: '20px', marginBottom: '10px'}}> <b>Expert Advice</b></td>                        
                        <td style={{verticalAlign: 'top', marginTop: '20px', marginBottom: '10px'}}> 
                            <div  dangerouslySetInnerHTML={{ __html: adviceItem.userAdvice}}></div> 
                            <br />
                           
                        
                       {/* <TextField
                                id="outlined-multiline-flexible"
                                defaultValue= { adviceItem.userAdvice}
                                variant='standard'
                                multiline
                                fullWidth
                                InputProps={{ readOnly: true, disableUnderline: true  ,  style: {fontSize: '14px'}
                                            }}                              
                                />   */}
                          </td>
                    </tr>
                    <tr>
                        <td style={{verticalAlign: 'top'}}> <b>Approved Advice</b></td>                        
                        <td> 
                          <Editor value={advice } onChange={handleApprovedAdviceChange} />
                          <p style={{color: 'red'}}>* Use 'Clear Formatting' button to remove style.</p>
                          {/*   <TextField
                                id="outlined-multiline-flexible"
                                defaultValue= {advice}
                                placeholder={adviceItem?.approvedAdvice?.length > 0 ? '' : 'enter approved advice here' }
                                multiline
                                fullWidth
                                rows={5}
                                sx={{minWidth: '700px'}}
                               // value={}
                                onChange={handleApprovedAdviceChange}
                            />   */} 
                           <br />
                                                   
                        </td>
                    </tr>
                    <tr>
                        <td style={{verticalAlign: 'top'}}> <b>Output display in KN</b></td>                        
                        <td> 
                        <div  dangerouslySetInnerHTML={{ __html: advice}}></div> 
                        </td>
                    </tr>
                    {dialogType === 'Complete' && 
                    
                    <tr>
                    <td style={{verticalAlign: 'top'}}> <b>Admin Action Date</b></td>                        
                    <td>
                        {adviceItem.adminActionDate}                        
                    </td>
                   </tr>
                     }
                    </tbody> 
                </table>
                </DialogContent>             
               
                <DialogActions>
                    <Button   variant="contained" onClick={handleInprogress} >
                        In Progress 
                    </Button>    
                    <Button   variant="contained" onClick={handleComplete} >
                        Complete
                    </Button>         
                </DialogActions>
               
            </> 
         
         }
         {adviceItem === undefined &&  //Case when item is undefined ==> no item found
            <>
              <DialogContent>          
                <p> Not able to load expert advice item detail. Advice item is 'undefined'. (Meaning no item pass to this dialog) </p>
            
            </DialogContent>
            </>
         }

        

    </Dialog>

 
          

        </>

    );
    

}