import * as React from 'react';
import { createContext , useReducer, Dispatch } from 'react';
import { UserFeedbackContextType, UserFeedbackDto } from '../../models/Types';
//import { updateFeedback, fetchFeedback } from './FeedbackActions' ;
import { feedbackReducer } from './FeedbackReducer'; 
import {  FETCH_DATA_ERROR, STATUS_FAILED, STATUS_SUCCESSFUL } from '../../constants/constants';
import * as feedbackApi from '../../api/UserFeedbackApi'; 

/*
*/
 
export const FeedbackContext = createContext<UserFeedbackContextType | null>(null);

/*

const initialState = {
  feedbacklist: [] as UserFeedbackDto[] ,
  isLoading : true,
  hasLoadingError : false,
  hasUpdateError : false, 
  //showsnackbar : false
 }

export const FeedbackContext = createContext<{
  state: UserFeedbackContextType;
  dispatch: Dispatch<any>;
}>({
  state: initialState,
  dispatch: () => {}
}); */

export const UserFeedbackProvider : React.FC<{children: React.ReactNode}> = ({ children }) => {

 const initialState = {
  feedbacklist: [] as UserFeedbackDto[] ,
  isLoading : true,
  hasLoadingError : false,
 // hasUpdateError : false, 
  //showsnackbar : false
 }

 const [state, dispatch] = useReducer(feedbackReducer, initialState);
 
 /* 
 */

 

 const fetchFeedback = async () => {
  const response  = await feedbackApi.getAllFeedback();     

 // return response
  /* todo ==> clean up later */
  
  dispatch({
    type : 'GET_FEEDBACK',
    payload: response === FETCH_DATA_ERROR ? [] as UserFeedbackDto[] : response,
    hasLoadingError : response === FETCH_DATA_ERROR ? true : false
   });


};
 
/* not USED. api call directly from component */
// Update feedback item
 const updateFeedback = async (updItem: UserFeedbackDto ) => {

  const response = await feedbackApi.updateFeedback(updItem);     
 //  return response; 
 
/*  todo ==> clean up later */
  dispatch({
    type : 'UPDATE_FEEDBACK',
    hasUpdateError : response === STATUS_SUCCESSFUL ? false : true,        

   }) ; 
   //dispatch fetch again to update the whole list ==> only updating one row causes inconsistance of data state
   // fetchFeedback();
} 


  return (
    <FeedbackContext.Provider
      value={{
        feedbacklist: state.feedbacklist,
        isLoading : state.isLoading,
       // hasUpdateError: state.hasUpdateError, 
        hasLoadingError: state.hasLoadingError,       
      //  showSnackbar: state.showsnackbar,

         updateFeedback,
        fetchFeedback,
       /*  resetSnackbar,  
       
     new but didn't work with typescript
        since dispatch is not contextType  */
       ...state, /* padding all state as spread */
        dispatch /* seems like this is not working */
      }}
    >
      {children}
    </FeedbackContext.Provider>
  )
}

export default UserFeedbackProvider
