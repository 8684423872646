import * as React from 'react';
import { ExpertAdviceProvider } from '../../context/ExpertAdvice/ExpertAdviceContext';
import { ExpertAdviceWrapper } from './ExpertAdviceWrapper'; 
import { SnackbarProvider } from '../../context/Snackbar/SnackbarContext' ; 

interface TemplatePageProps {
    
}


//move this to common file if there is more stuff like this

export const ExpertAdvice = ({} : TemplatePageProps ) => {
  
    return (      
                
         <ExpertAdviceProvider>  
             <SnackbarProvider>
                 <ExpertAdviceWrapper />
            </SnackbarProvider>
        </ExpertAdviceProvider>    
   
   );
    

}