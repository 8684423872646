import * as React from 'react';
import { useState, useEffect } from 'react'; 
import Container from 'react-bootstrap/Container';
import Stack from '@mui/material/Stack'; 
import Button from '@mui/material/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import { RebaseObjectProgress } from './RebaseObject_Progress'; 
import {STATUS_FAILED, STATUS_IN_PROGRESS, STATUS_SUCCESSFUL} from '../../constants/constants'; 
import { useRebase_Objects, useRebase_InsertAllObjects  } from '../../hooks/useRebaseSearchTable'; 

interface TemplatePageProps {
    
}

//move this to common file if there is more stuff like this

export const ReInexAzureSearchTable = ({} : TemplatePageProps ) => {
 
 const [inProgress, setInProgress] = useState(false);
 const [startFinalStep, setStartFinalStep]= useState(false); 
 const [isStep2Ready, setStep2Ready] = useState(false); 

 //baseobject : cube, report, table, term & wsresource
 const { resultCount : baseObjectCount , status : baseobjectStaus } = useRebase_Objects(inProgress, 'baseobjects'); 
 //column
 const { resultCount : columnCount , status :  columnStatus} = useRebase_Objects(inProgress, 'column'); 
 //cubefield
 const { resultCount : cubefieldCount , status : cubefieldStatus } = useRebase_Objects(inProgress, 'cubefield'); 
  //wsfield
  const { resultCount : wsfieldCount , status : wsfieldStatus  } = useRebase_Objects(inProgress, 'wsfield'); 
   //diagram
 const { resultCount : diagramCount , status : diagramStatus } = useRebase_Objects(inProgress, 'diagram'); 
  //webservice
  const { resultCount : wsCount , status : wsStatus } = useRebase_Objects(inProgress, 'webservice'); 

  //here total all call
   const { resultCount: totalcount, status  } = useRebase_InsertAllObjects(isStep2Ready);
 
 const handleStep1Click = () => {
    setInProgress(true);
 }

 const handleStep2Click = () => {
        setStep2Ready(true);
 }

 //when is done 
 useEffect(()=> {
  
        //when all returns status of successful or failed
  if(baseobjectStaus !== STATUS_IN_PROGRESS &&
     columnStatus !== STATUS_IN_PROGRESS &&
     cubefieldStatus !== STATUS_IN_PROGRESS &&
     wsfieldStatus !== STATUS_IN_PROGRESS &&
     diagramStatus !== STATUS_IN_PROGRESS &&
     wsStatus !== STATUS_IN_PROGRESS  ){
       
        setStartFinalStep(true);
   }
  
  //setProcessDone(true);

 }, [baseobjectStaus, columnStatus, cubefieldStatus, wsfieldStatus, diagramStatus, wsStatus]);


    return (       
        <>
        <Container>
                 <h4>Rebase Azure Search Table</h4>
               
                <Row>
                        <Col>   
                                <div className="bg-light border" style={{padding: '6px 6px', minHeight: '400px'}}>  
                                     <Button variant="contained" disabled={inProgress} fullWidth 
                                          onClick={handleStep1Click}>  Step 1: {inProgress ? 'In Progress...' : 'Start staging'}                                     
                                     </Button>                                        
                                        <div>                                     
                                        {inProgress &&    
                                          <>
                                           <p style={{fontSize: '12px'}}>Inserting Neo4j Objects to Azure Sql Staging Table...</p>
                                          
                                           <RebaseObjectProgress label='Base Objects (Cube, Report, Table, Term, WSResource)'
                                               count={baseObjectCount} status ={baseobjectStaus} />
                                          
                                           <RebaseObjectProgress label='Column'
                                               count={columnCount} status ={columnStatus} />
                                           <RebaseObjectProgress label='CubeField'
                                               count={cubefieldCount} status ={cubefieldStatus} />
                                           <RebaseObjectProgress label='WSField'
                                               count={wsfieldCount} status ={wsfieldStatus} />
                                           <RebaseObjectProgress label='Diagram'
                                               count={diagramCount} status ={diagramStatus} />
                                           <RebaseObjectProgress label='WebService'
                                               count={wsCount} status ={wsStatus} />



                                         </>
                                        }

                                        </div>
                                </div>                               
                               
                        </Col>
                        <Col>
                           <div className="bg-light border" style={{padding: '6px 6px',  minHeight: '400px'}}>   
                                <Button variant="contained" disabled={!startFinalStep || isStep2Ready} fullWidth onClick={handleStep2Click}>
                                        Step 2: Push staging table to KN_Search table </Button>
                                        
                                          {isStep2Ready && 
                                         
                                                <>
                                                <p style={{fontSize: '12px'}}>Pushing to KN_Search table...</p>
                                                
                                                <RebaseObjectProgress label='Total KN_Search Table'
                                                count={totalcount} status ={status} />
                                        
                                               {status === STATUS_FAILED &&  
                                                <div style={{padding: '6px 6px', backgroundColor: 'red', color: '#fff'}}>
                                
                                                        <p> Failed to insert staging data to  KN_Search table.</p> 
                                                        <p> Please try it again by clicking 'Rebase Search' button or let admin know.</p>
                                                        
                                                </div>  
                                                } 
                                                 {status === STATUS_SUCCESSFUL &&  
                                                <div style={{padding: '6px 6px', backgroundColor: 'green', color: '#fff'}}>
                                
                                                        <p> Complete!</p>                                                        
                                                </div>  
                                                } 
                                           </>
                                                
                                        }

                           </div>
                         </Col>
                </Row>
             <Stack gap={3}>
               
              
                {inProgress && 
                <>
               

                    
                       
                     {/*  <div className="bg-light border" style={{padding: '6px 6px'}}>
                                <Row>
                                        <Col> Column</Col>
                                        <Col> { columnCount > 0 ? 'Done (' + columnCount + ')' : <Spinner animation="border" size="sm" /> }</Col>
                                </Row>
                        
                        </div>
                         <div className="bg-light border" style={{padding: '6px 6px'}}>
                        
                                <Row>
                                        <Col> Cube Field</Col>
                                        <Col>  { cubefieldCount > 0 ? 'Done (' + cubefieldCount + ')' : <Spinner animation="border" size="sm" /> }</Col>
                                </Row>
                        </div>
                        <div className="bg-light border" style={{padding: '6px 6px'}}>
                        
                                <Row>
                                        <Col> WSField</Col>
                                        <Col>  { wsfieldCount > 0 ? 'Done (' + wsfieldCount + ')' : <Spinner animation="border" size="sm" /> }</Col>
                                </Row>
                        </div>
                        <div className="bg-light border" style={{padding: '6px 6px'}}>
                                
                                <Row>
                                        <Col> Web Service</Col>
                                        <Col>  { wsCount > 0 ? 'Done (' + wsCount + ')' : <Spinner animation="border" size="sm" /> }</Col>
                                </Row>
                        </div>
                        <div className="bg-light border" style={{padding: '6px 6px'}}>
                        
                                <Row>
                                        <Col> Diagram</Col>
                                        <Col> { diagramCount > 0 ? 'Done (' + diagramCount + ')' : <Spinner animation="border" size="sm" /> }</Col>
                                </Row>
                       </div>   */}
                      
                     
                   
                       {/*  */}

                     

                </>
             }
             
             
                         
             
             
            </Stack>

        </Container>
        

        </>

    );
    

}