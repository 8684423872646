import * as React from 'react';
import { useState, useEffect, useContext,} from 'react';
import * as Type from '../../models/Types';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
//import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {  Close, } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';


import { DialogFunctionContext } from '../../context/DialogFunctionContext';



interface UserFeedbackModalProps {
    feedback : Type.UserFeedbackDto | undefined ,
    show: boolean,
  //  dialogType : string,
   // status : number
}


//move this to common file if there is more stuff like this

export const UserFeedbackCompleteModal = ({ feedback, show} : UserFeedbackModalProps ) => {
   //feedback could be null
    const dialogContext = useContext(DialogFunctionContext); 
    const [showDialog, setShowDialog] = useState(show);  
   /**/

  useEffect(() =>{
   //when each time show value change, 
    setShowDialog(show);
  }, [show]);

    return (       
        <>
         

          <Dialog
             open={showDialog}
             onClose={(event, reason) => {
                if(reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                    // Set 'open' to false, however you would do that with your particular code.
                    setShowDialog(false);
                    dialogContext.closeDialog(false); 
                    //reset the hasUpdateError
                }
            }}
           
            fullWidth={true}              
            disableEscapeKeyDown
            maxWidth={'md'}
            >
          
            {feedback !== undefined && 
            <>  
                <DialogTitle sx={{ m: 0, p: 1 , borderBottom: 'solid 1px #ccc'}}>                   
                    {feedback?.title} ({feedback.id}) 
                    <IconButton sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }} onClick={() => { setShowDialog(false);  dialogContext.closeDialog(false);  }}>
                                <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{marginTop: 1}}> 
                   
                <table cellPadding={0} cellSpacing={0}>
                   <tbody>
                   <tr>
                        <td style={{verticalAlign: 'top', width: '150px'}}> Current State</td>                        
                        <td style={{verticalAlign: 'top'}}><p>Completed</p>   </td>
                    </tr>
                    <tr>
                        <td style={{verticalAlign: 'top', width: '150px'}}> <b>Submit Date</b></td>                        
                        <td style={{verticalAlign: 'top'}}><p>{feedback.submitDate !== null ? feedback.submitDate : 'n/a'}</p>   </td>
                    </tr>
                    <tr>
                        <td style={{verticalAlign: 'top', width: '150px'}}> KN Page Title</td>                        
                        <td style={{verticalAlign: 'top'}}><p>{feedback.title}</p>   </td>
                    </tr>
                    <tr>
                        <td style={{verticalAlign: 'top'}}> KN Object</td>                        
                        <td><p>{feedback.knObjectType}</p>   </td>
                    </tr>
                    <tr>
                        <td style={{verticalAlign: 'top'}}>Page Url</td>                        
                        <td><p><a href={feedback.knPageUrl} target='_blank'>{feedback.knPageUrl}</a></p></td>
                    </tr>
                    <tr>
                        <td style={{verticalAlign: 'top'}}>KN UID</td>                        
                        <td><p>{feedback.knUid}</p>   </td>
                    </tr>
                    <tr>
                        <td style={{verticalAlign: 'top'}}>User Info</td>                        
                        <td><p>{feedback.userName} ({feedback.userNetId}@uw.edu)</p>      </td>
                    </tr>
                    <tr>
                        <td style={{verticalAlign: 'top'}}> <b>User Feedback</b></td>                        
                        <td>  <div dangerouslySetInnerHTML={{ __html: feedback.feedback }}></div> 
                        {/*<TextField
                                id="outlined-multiline-flexible"
                                defaultValue= { feedback.feedback }
                                variant='standard'
                                multiline
                                fullWidth
                                InputProps={{ readOnly: true, disableUnderline: true  ,  style: {fontSize: '14px'}
                                            }}                              
                                />    */}
                                <br />   
                          </td>
                    </tr>
                    <tr>
                        <td style={{verticalAlign: 'top'}}> <b>Action Steps</b></td>                        
                        <td>
                            <div dangerouslySetInnerHTML={{ __html: feedback.actionSteps }}></div>
                          
                           {/*  <TextField
                                id="outlined-multiline-flexible"
                                defaultValue= { feedback.actionSteps }
                                variant='standard'
                                multiline
                                fullWidth
                                InputProps={{ readOnly: true, disableUnderline: true  ,  style: {fontSize: '14px'}
                                            }}                              
                                />   */}

                          
                           {feedback.actionSteps !== null && feedback.actionSteps !== '' ?  <br /> : <p>&nbsp;</p> }              
                        </td>
                    </tr>
                    <tr>
                        <td style={{verticalAlign: 'top'}}> <b>Admin Action Date</b></td>                        
                        <td>
                            {feedback.adminActionDate}                        
                        </td>
                    </tr>
                   
                    </tbody> 
                </table>
                </DialogContent>   
            </> 
         
         }
         {feedback === undefined &&  //Case when item is undefined ==> no item found
            <>
              <DialogContent>          
                <p> Not able to load feedback item detail. Feedback item is 'undefined'. (Meaning no item pass to this dialog) </p>
            
            </DialogContent>
            </>
         }
       
    </Dialog>


          

        </>

    );
    

}