import * as React from 'react';
import { UserFeedbackProvider } from '../../context/Feedback/FeedbackContext' ; 
import { UserFeedbackWrapper } from './UserFeedbackWrapper'; 
import { SnackbarProvider } from '../../context/Snackbar/SnackbarContext' ; 

interface TemplatePageProps {
    
}


//move this to common file if there is more stuff like this

export const UserFeedback = ({} : TemplatePageProps ) => {
  
    return (      
                
         <UserFeedbackProvider> 
            <SnackbarProvider>
                 <UserFeedbackWrapper />                
            </SnackbarProvider>            
        </UserFeedbackProvider>    
   
   );
    

}