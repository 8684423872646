import * as React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import {  FETCH_DATA_ERROR , STATUS_FAILED, STATUS_SUCCESSFUL, STATUS_IN_PROGRESS} from '../../constants/constants'; 
import CheckIcon from '@mui/icons-material/Check';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';

interface TemplatePageProps {
    status : string
    count : number, 
    label : string
}


//move this to common file if there is more stuff like this

export const RebaseObjectProgress = ({status, count, label } : TemplatePageProps ) => {
 
 
    return (       
        <>
            <div style={{padding: '6px 6px', border: 'solid 1px #ccc', backgroundColor : '#fff'}}>                    
                            <Row>
                                <Col> {label} </Col>
                                <Col> 
                                 {status === STATUS_IN_PROGRESS &&  <Spinner animation="border" size="sm" />  }
                                 {status === STATUS_SUCCESSFUL &&  <><CheckIcon style={{color: 'green'}} />  <span> {count.toLocaleString("en-US")} rows</span> </> }
                                 {status === STATUS_FAILED &&  <DisabledByDefaultIcon style={{color: 'red'}} /> }
                                
                                  </Col>
                           </Row>
             </div>

        </>

    );
    

}