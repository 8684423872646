import axios from 'axios';
import {  FETCH_DATA_ERROR } from '../constants/constants';


export const postRebase_Objects = async (label: string) => {
  try {
    const { data  } = await axios.post('/api/rebase/' + label);
 
    return data;
  } catch (err) {
    console.error(err);
    const errMsg = FETCH_DATA_ERROR;
    return errMsg;
  }
};

/*
export const postRebase_Objects = async () => {
    try {
      const { data  } = await axios.post('/api/rebase/baseobjects');
   
      return data;
    } catch (err) {
      console.error(err);
      const errMsg = FETCH_DATA_ERROR;
      return errMsg;
    }
  };

  export const postRebase_Column = async () => {
    try {
      const { data  } = await axios.post('/api/rebase/column');
   
      return data;
    } catch (err) {
      console.error(err);
      const errMsg = FETCH_DATA_ERROR;
      return errMsg;
    }
  };

  */