import * as React from 'react';
import { createContext , useReducer} from 'react';
import { SnackbarContextType } from '../../models/Types';

import { snackbarReducer } from './SnackbarReducer'; 
/*
*/

export const SnackbarContext = createContext<SnackbarContextType| null>(null);

export const SnackbarProvider : React.FC<{children: React.ReactNode}> = ({ children }) => {

 const initialState =  {
    showsnackbar : false,
    msg: '',
    bgColor: ''
 }

 const [state, dispatch] = useReducer(snackbarReducer, initialState);
 
 /* */ 
 
 const setSnackbar = (  msg: any, bgColor: any ) => {
       //  console.log('dispatch snackbar'); 
    dispatch({
      type : 'SET_SNACKBAR'     ,
      payload: {  msg, bgColor}
     });
    // console.log('snack state', state); 
  } 

  /* */

  const removeSnackbar = () => {
         
    dispatch({
      type : 'REMOVE_SNACKBAR'
     });
  } 



  return (
    <SnackbarContext.Provider
      value={{
        showSnackbar : state.showSnackbar,
        msg : state.msg,
        bgColor : state.bgColor,
        setSnackbar,
        removeSnackbar
      }}
    >
      {children}
    </SnackbarContext.Provider>
  )
}

export default SnackbarProvider
