import * as React from 'react';
import { useState, useContext } from 'react'; 
import { DataGrid,  GridColDef, GridRowParams,  } from '@mui/x-data-grid';
import * as Type from '../../models/Types';
import { UserFeedbackModal } from "./UserFeedbackModal"; 
import { UserFeedbackCompleteModal } from './UserFeedbackCopmpleteReadonlyModal'; 
import { DialogFunctionContext } from '../../context/DialogFunctionContext';
import { FeedbackContext } from '../../context/Feedback/FeedbackContext' ; 
import { SnackbarContext } from '../../context/Snackbar/SnackbarContext'
import { SnackbarContextType } from '../../models/Types'; 

interface UserFeedbackListProps {
    status: number,  //1-todo, 2-in progress, 3-complete
    feedbacklist : Type.UserFeedbackDto[],
}


export const UserFeedbackList = ({status, feedbacklist} : UserFeedbackListProps ) => {
 //get data and 
 //also get data when modal trigger status change 
 //set the state when data is done
   const [showDialog, setShowDialog] = useState(false); 
   const [ feedbackitem , setFeedbackitem] = useState<Type.UserFeedbackDto>(); //default, undefined as default value 
   const { removeSnackbar } =useContext(SnackbarContext) as SnackbarContextType;
 // console.log('list view', feedbacklist); 
      
      const columns: GridColDef[] = [
        { field: 'id', headerName: 'Id', width: 50 },
        { field: 'submitDate', headerName: 'Submit Date', width: 100,  type: 'date',
          valueGetter: (params) => {
            //if(!params.value){
              return new Date(params.value)
            //}
          },
         // valueFormatter: (params) => { return params.value }, 
       },
       { field: 'title', headerName: 'Title', width: 200},
        { field: 'knObjectType', headerName: 'KN Object', width: 150},
        { field: 'feedback', headerName: 'Feedback', width: 330 },
        { field: 'userNetId', headerName: 'User NetId', width: 100 },
        //{ field: 'test', headerName: 'hidden', width: 100, hideable: true}
      ];


     const handleRowClick = (params: GridRowParams ) =>{     
      //open modal      
      setFeedbackitem(params.row)
      setShowDialog(true);      

      //reset the snackbar
      removeSnackbar();
     // console.log('newitem', params.row);  
     } 

     const handleCloseDialog = (showDialog : boolean) => {
      setShowDialog(showDialog); //closing dialog //which is value would be false always since it is closing it from child 
     }
    return (       
        <>
           <div style={{  width: '100%' }}>
                 <DataGrid rows={feedbacklist} columns={columns} density='compact' 
                   
                    initialState={{
                      pagination: {
                        paginationModel: {
                          pageSize: 10,
                        },
                      },
                    }}
                    pageSizeOptions={[10,20,30,40,50,]}
                    onRowClick={handleRowClick}
                   sx={{"& .MuiDataGrid-row:hover" : {
                       cursor: 'pointer'                     
                   }}}
               
                 />
            </div>
            <DialogFunctionContext.Provider value={{showDialog, closeDialog: handleCloseDialog}} >
               {status !== 3 && 
                 <UserFeedbackModal show={showDialog} feedback={feedbackitem}  dialogType={status ===1 ? 'To Do' : status === 2 ? 'In Progress' : 'Complete'} />
               }
               {status  === 3 &&
                <UserFeedbackCompleteModal  show={showDialog} feedback={feedbackitem}/>
               }
                 </DialogFunctionContext.Provider>         
                    
        </>

    );
    

}