import axios from 'axios';
import {  FETCH_DATA_ERROR, STATUS_FAILED, STATUS_SUCCESSFUL } from '../constants/constants';
import { UserFeedbackDto, ExpertAdviceDto } from '../models/Types';

/*feedback */
export const getAllFeedback = async () => {
  try {
    const { data  } = await axios.get('/api/feedback');
 
    return data;
  } catch (err) {
    console.error(err);
    const errMsg = FETCH_DATA_ERROR;
    return errMsg;
  }
};


export const updateFeedback = async (_item : UserFeedbackDto) =>{
    try {    
     
        await axios.post('api/feedback/update', _item);
        return STATUS_SUCCESSFUL;
      } catch (err) {
        console.error(err);
        const errMsg = STATUS_FAILED;
        return errMsg;
      }

}

export const searchFeedback = async (_duration: string, _knobject: string, _netId: string) => {
   
    try {
      
          const data = {
           duration : _duration,
           knobject : _knobject,
           netId: _netId
          };

        const  response   =  await axios.post('api/feedback/complete',data );
        // console.log('response', response); 
        return response.data;

      } catch (err) {
        console.error(err);
        const errMsg = FETCH_DATA_ERROR;
        return errMsg;
      }

}


/*expert advise */

export const getAllExpertAdvice = async () => {
    try {
      const { data  } = await axios.get('/api/advice');
   
      return data;
    } catch (err) {
      console.error(err);
      const errMsg = FETCH_DATA_ERROR;
      return errMsg;
    }
  };
  
  export const updateAdvice = async (_item : ExpertAdviceDto) =>{
    try {    
     
        await axios.post('api/advise/update', _item);
        return STATUS_SUCCESSFUL;
      } catch (err) {
        console.error(err);
        const errMsg = STATUS_FAILED;
        return errMsg;
      }

}