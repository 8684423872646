
//import { FetchData } from "./components/FetchData";
import { Home } from "./components/Home";
import { ReInexAzureSearchTable } from './views/re-index-search-page/Rebase'
import { UserFeedback } from './views/user-feedback-page/UserFeedbackPage'
import { UserFeedbackCompletePage } from "./views/user-feedback-page/UserFeedbackCompletePage";
import { ExpertAdvice } from './views/expert-advice-page/ExpertAdvicePage'; 
import { ExpertAdviceCompletePage } from './views/expert-advice-page/ExpertAdviceCompletedPage'; 

const AppRoutes = [
  {
    index: true,
    element: <Home />
  },
  {
    path: '/home',
    element: <Home />
  },
  {
    path: '/search',
    element: <ReInexAzureSearchTable />
  },
  {
    path: '/feedback',
    element: <UserFeedback />
  },
  {
    path: '/feedback_completed',
    element: <UserFeedbackCompletePage />
  },
  {
    path: '/advice',
    element:  <ExpertAdvice />
  },
 {
  path: '/advice_completed',
  element: <ExpertAdviceCompletePage />
 }

];

export default AppRoutes;
