import axios from 'axios';
import {  FETCH_DATA_ERROR, STATUS_FAILED, STATUS_SUCCESSFUL } from '../constants/constants';
import {  ExpertAdviceDto } from '../models/Types';


/*expert advise */

export const getAllExpertAdvice = async () => {
    try {
      const { data  } = await axios.get('/api/advice');
   
      return data;
    } catch (err) {
      console.error(err);
      const errMsg = FETCH_DATA_ERROR;
      return errMsg;
    }
  };
  
  export const updateAdvice = async (_item : ExpertAdviceDto) =>{
    try {    
    // console.log('update advice', _item);

        await axios.post('api/advice/update', _item);
        return STATUS_SUCCESSFUL;
      } catch (err) {
        console.error(err);
        const errMsg = STATUS_FAILED;
        return errMsg;
      }

}


export const searchExpertAdvice = async (_duration: string, _knobject: string, _netId: string) => {
   
    try {
      
          const data = {
           duration : _duration,
           knobject : _knobject,
           netId: _netId
          };

        const  response   =  await axios.post('api/advice/complete',data );
        // console.log('response', response); 
        return response.data;

      } catch (err) {
        console.error(err);
        const errMsg = FETCH_DATA_ERROR;
        return errMsg;
      }

}

export const getExpertAdviceItem = async (id : number ) => {

  try {
    const { data } = await axios.get('api/advice/' + id);
       return data;
  } catch (err) {
     console.error(err);
      const errMsg = FETCH_DATA_ERROR;
    return errMsg;
  }

}