
export const expertAdviceReducer = (state : any, action : any) =>{

    switch(action.type){

        case 'GET_EXPERTADVICE' :
            return {
                ...state,
                advicelist: action.payload,
                isLoading: false,
                hasLoadingError : action.hasLoadingError
            }
        case 'UPDATE_EXPERTADVICE' :
            return {
                ...state,
                showsnackbar: true,
                hasUpdateError : action.hasUpdateError,
                advicelist: action.payload
            }

        case 'SET_SNACKBAR' :
            return {
                ...state,
                showsnackbar: false        //resetting         
            }
        default:
            return state;
    }
}

