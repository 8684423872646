import * as React from 'react';
import { useState, useEffect, useContext, ChangeEvent } from 'react';
import * as Type from '../../models/Types';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
//import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {  Close, } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button'; 
//import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { DialogFunctionContext } from '../../context/DialogFunctionContext';
import { FeedbackContext } from '../../context/Feedback/FeedbackContext' ; 
import { SnackbarContext } from '../../context/Snackbar/SnackbarContext'; 
import { SnackbarContextType, UserFeedbackContextType } from '../../models/Types'; 
import Editor ,  { ContentEditableEvent,  } from 'react-simple-wysiwyg';
//import Typography from '@mui/material/Typography';

//import { updateFeedback, fetchFeedback  } from '../../context/Feedback/FeedbackActions';
// import {  FETCH_DATA_ERROR, STATUS_FAILED, STATUS_SUCCESSFUL } from '../../constants/constants';
import  * as feedbackApi from '../../api/UserFeedbackApi' ; 

interface UserFeedbackModalProps {
    feedback : Type.UserFeedbackDto | undefined ,
    show: boolean,
    dialogType : string, 
   // status : number
}


//move this to common file if there is more stuff like this

export const UserFeedbackModal = ({ feedback, show, dialogType} : UserFeedbackModalProps ) => {
   //feedback could be null
    const dialogContext = useContext(DialogFunctionContext); 
    const [showDialog, setShowDialog] = useState(show);
    const { fetchFeedback  }  = useContext(FeedbackContext) as UserFeedbackContextType;
    const [ _actionSteps , setActionSteps ] = useState(feedback === undefined ? '' : feedback.actionSteps);  
    const { setSnackbar } = useContext(SnackbarContext) as SnackbarContextType;
   /**/

 //  console.log('feedback', feedback);

   //bind _actionSteps
   useEffect(() =>{   
   // console.log('a'); 
    setActionSteps(feedback === undefined ? '' : feedback.actionSteps);
   }, [feedback]); //when feedback is loaded, bind it again


  useEffect(() =>{
   //when each time show value change, 
    setShowDialog(show);
  }, [show]);


  const handleActionStepChange = (event:ContentEditableEvent) =>{
     setActionSteps(event.target.value);
  }
  /* const handleActionStepChange = (event:ChangeEvent<HTMLInputElement>) =>{
    setActionSteps(event.target.value);
 } */

  const handleInprogress = async () => {
   
    if(feedback !== undefined){ 
       //save data by directly call api here to catch response 
       //depending on response, we can call snackbar action 
       const response = await feedbackApi.updateFeedback({ ...feedback, actionSteps: _actionSteps === null ?  '' : _actionSteps, status: 2 });     
       //  updateFeedback({ ...feedback, actionSteps: _actionSteps, status: 2 });      
       //show snackbar     
      if(response === 'Failed'){
        setSnackbar('Update Failed.', 'red');
       }else{
        setSnackbar('Update Successful!', 'green');
       }
     
       //refetch updated data
       fetchFeedback();
     
       //close dialog
      setShowDialog(false);  
      dialogContext.closeDialog(false);  
    }      
  }
  
  const handleComplete = async () => {
    // console.log('_actionSteps', _actionSteps);

    if(feedback !== undefined){ 
     //save data by directly call api here to catch response 
       //depending on response, we can call snackbar action 
       const response = await feedbackApi.updateFeedback({ ...feedback, actionSteps: _actionSteps === null ?  '' : _actionSteps , status: 3 });     
   
       //show snackbar     
      if(response === 'Failed'){
        setSnackbar('Update Failed.', 'red');
       }else{
        setSnackbar('Update Successful!', 'green');
       }
     
       //refetch updated data
       fetchFeedback();
     
       //close dialog
      setShowDialog(false);  
      dialogContext.closeDialog(false);  
    }   
  }

    return (       
        <>
         

          <Dialog
             open={showDialog}
             onClose={(event, reason) => {
                if(reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                    // Set 'open' to false, however you would do that with your particular code.
                    setShowDialog(false);
                    dialogContext.closeDialog(false); 
                    //reset the hasUpdateError
                }
            }}
           
            fullWidth={true}              
            disableEscapeKeyDown
            maxWidth={'md'}
            >
          
            {feedback !== undefined && 
            <>  
                <DialogTitle sx={{ m: 0, p: 1 , borderBottom: 'solid 1px #ccc'}}>                    
                    <> Edit: {feedback?.title} ({feedback.id}) </>
                    <IconButton sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }} onClick={() => {  fetchFeedback(); setShowDialog(false);  dialogContext.closeDialog(false);  }}>
                                <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{marginTop: 1}}> 
                   
                <table cellPadding={0} cellSpacing={0}>
                   <tbody>
                    <tr>
                        <td style={{verticalAlign: 'top', width: '150px'}}> Current State</td>                        
                        <td style={{verticalAlign: 'top'}}><p>{dialogType}</p>   </td>
                    </tr>
                    <tr>
                        <td style={{verticalAlign: 'top', width: '150px'}}> <b>Submit Date</b></td>                        
                        <td style={{verticalAlign: 'top'}}><p>{feedback.submitDate !== null ? feedback.submitDate : 'n/a'}</p>   </td>
                    </tr>
                    <tr>
                        <td style={{verticalAlign: 'top', width: '150px'}}> KN Page Title</td>                        
                        <td style={{verticalAlign: 'top'}}><p>{feedback.title}</p>   </td>
                    </tr>
                    <tr>
                        <td style={{verticalAlign: 'top'}}> KN Object</td>                        
                        <td><p>{feedback.knObjectType}</p>   </td>
                    </tr>
                    <tr>
                        <td style={{verticalAlign: 'top'}}>Page Url</td>                        
                        <td><p><a href={feedback.knPageUrl} target='_blank'>{feedback.knPageUrl}</a></p>   </td>
                    </tr>
                    <tr>
                        <td style={{verticalAlign: 'top'}}>KN UID</td>                        
                        <td><p>{feedback.knUid}</p>   </td>
                    </tr>
                    <tr>
                        <td style={{verticalAlign: 'top'}}>User Info</td>                        
                        <td><p>{feedback.userName} ({feedback.userNetId}@uw.edu)</p>      </td>
                    </tr>
                    <tr>
                        <td style={{verticalAlign: 'top'}}> <b>User Feedback</b></td>                        
                        <td>
                         <div dangerouslySetInnerHTML={{ __html: feedback.feedback }}></div> 
                            
                             {/*read only text field 
                            <TextField
                                id="outlined-multiline-flexible"
                                defaultValue= { feedback.feedback }
                                variant='standard'
                                multiline
                                fullWidth
                                InputProps={{ readOnly: true, disableUnderline: true  ,  style: {fontSize: '14px'}
                                            }}                              
                                />   */}
                             <br />
                          </td>
                    </tr>
                    <tr>
                        <td style={{verticalAlign: 'top'}}> <b>Action Steps</b></td>                        
                        <td> 
                            {/* old textbox that doesn't caputre line break 
                            <TextField
                                id="outlined-multiline-flexible"
                                defaultValue= { feedback.actionSteps }
                                placeholder={feedback?.actionSteps?.length > 0 ? '' : 'enter action steps here' }
                                multiline
                                fullWidth
                                rows={5}
                                sx={{minWidth: '700px'}}
                               // value={}
                                onChange={handleActionStepChange}
                                />  */}
                                <div style={{height: '10px'}}></div>
                         <Editor value={_actionSteps } onChange={handleActionStepChange} /> {/* */}
                              
                              {/* editor without toolbar 
                              <EditorProvider>
                                <Editor value={_actionSteps} onChange={handleActionStepChange}>                                  
                                </Editor>
                                </EditorProvider> */}
                        </td>
                    </tr>
                    </tbody> 
                </table>
                </DialogContent>             
               
                <DialogActions>
                    <Button   variant="contained" onClick={handleInprogress} >
                        In Progress 
                    </Button>    
                    <Button   variant="contained" onClick={handleComplete} >
                        Complete
                    </Button>         
                </DialogActions>
               
            </> 
         
         }
         {feedback === undefined &&  //Case when item is undefined ==> no item found
            <>
              <DialogContent>          
                <p> Not able to load feedback item detail. Feedback item is 'undefined'. (Meaning no item pass to this dialog) </p>
            
            </DialogContent>
            </>
         }
       
    </Dialog>


          

        </>

    );
    

}