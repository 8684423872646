import * as React from 'react';
//import { Component } from 'react'; 
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { SideNav } from './SideNav';
import { Header } from './Header'; 
import Paper from '@mui/material/Paper';

export const Layout  = (props: { children?: React.ReactNode }) => {
  
  return (

      <>
        <Header />
        <Container>         
          <Row>
               <Col  md={2}> <SideNav /></Col>
               <Col> 
                  <Paper style={{marginTop: "20px", minHeight: '45vh', padding: '20px', marginBottom: "60px"}}>{props.children}</Paper>  
               </Col>           
          </Row>        
        </Container>
      
      </>
   
   );
  
}
