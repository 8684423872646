import * as React from 'react';
import { useEffect, useState, useContext } from 'react';
import { ExpertAdviceDto, ExpertAdviceContextType, SnackbarContextType } from '../../models/Types'; 
import {  ExpertAdviceContext } from '../../context/ExpertAdvice/ExpertAdviceContext' ; 
import { ExpertAdivceList } from './ExpertAdviceList'; 
import Snackbar from '@mui/material/Snackbar';
import { SnackbarContext  } from '../../context/Snackbar/SnackbarContext'; 

interface TemplatePageProps {    
}


//move this to common file if there is more stuff like this

export const ExpertAdviceWrapper = ({} : TemplatePageProps ) => {
   // console.log('b');

    const { fetchExpertAdvice, advicelist,  isLoading,   hasLoadingError   } = useContext(ExpertAdviceContext) as ExpertAdviceContextType;
    const { showSnackbar, msg, bgColor }  = useContext(SnackbarContext)  as SnackbarContextType ; 
    const [ todoList, setTodoList] = useState<ExpertAdviceDto[]>([]); 
    const [ inProgressList, setInProgressList ] = useState<ExpertAdviceDto[]>([]); 
    const [ _showSnackbar, setShowSnackbar ] = useState(showSnackbar);  //on load, 
     
   /*
   */ 
    
   useEffect( () => {
    fetchExpertAdvice();
   }, [] ); //on component load, fetch feedback => this could be done in context ==>depending on reducer, will keep here for now.
   
   //console.log('advicelist', advicelist)
  
  useEffect(() => { 
    // console.log('hasLoadingError', hasLoadingError)
    if(!hasLoadingError){
        //when list change, bind the data
        setTodoList(advicelist !== null? advicelist.filter(s => s.status === 1) : []);
        setInProgressList(advicelist !== null ? advicelist.filter(s => s.status === 2) : []);   
    }
   
  }, [advicelist, hasLoadingError ] ); //on load, adviselist is [] then filled in

 useEffect(() =>{
   // console.log('snackbar open', showSnackbar ) ;
    setShowSnackbar(showSnackbar);
 }, [showSnackbar])

  const handleSnackbarClose = () =>{
    setShowSnackbar(false); 
  }


  /*  
 */
     


    return (       
          
      <>
            <h4>Manage Expert Advice</h4>

            {isLoading && <p>Loading...</p>}   
            {!isLoading && hasLoadingError && <p>Error while loading expert advice...ask moonk2</p>}

            {!isLoading && !hasLoadingError && 
            <>
                {/*  */}
                    <h5>To Do</h5>
                    {todoList.length > 0  && 
                    <ExpertAdivceList status={1} list = {todoList}></ExpertAdivceList>
                    }
                    {todoList.length  === 0 && 
                        <p>No To Do item found</p>
                    }

                    <div style={{height: '20px'}}></div>
                    
                    <h5>In Progress</h5>
                    {inProgressList.length > 0 &&
                    <ExpertAdivceList status={2}  list = {inProgressList}></ExpertAdivceList>
                    }
                    {inProgressList.length === 0  && 
                        <p>No In Progress item found</p>
                    }   

                    <div style={{height: '20px'}}></div>
                     {/*<hr />
                    completed filter area 
                    <b><Link to={'/completedfeedback'} >View Completed Feedback</Link></b><br /> */}
                    
                
            </>} 

               {/* snackbar */}
               <Snackbar
                            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                            open={_showSnackbar}
                            onClose={handleSnackbarClose}
                            message={msg}
                            autoHideDuration={5000}    
                            ContentProps={{sx: {background : bgColor  }
                        }}   
                        />  
        

    </>
              
    

    );
    

}