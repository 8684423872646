import { useState, useEffect } from 'react';
import {  FETCH_DATA_ERROR , STATUS_FAILED, STATUS_SUCCESSFUL, STATUS_IN_PROGRESS} from '../constants/constants';
import  * as rebaseApi from '../api/RebaseSearchTableApi'; 



const useRebase_Objects = (start: boolean, label: string) => {
    
  const [resultCount, setResultCount] =  useState(0); 
  const [ status, setStatus ] = useState(STATUS_IN_PROGRESS); 


  useEffect(() => {     
   
      const startRebaseBasicObjects = async () => {
      const cnt  = await rebaseApi.postRebase_Objects(label); 
     
      if (cnt === FETCH_DATA_ERROR) {
       // setError(true);
       // setErrorMsg(cnt)    
       setStatus(STATUS_FAILED);       
      } else {          
        setResultCount(cnt);    
        setStatus(STATUS_SUCCESSFUL);            
      }  
    };

    if(start){
      startRebaseBasicObjects();  
    }

    
  }, [start]);//runs once

  return {  resultCount,   status    };

};

/*   */
const useRebase_InsertAllObjects = (start: boolean) => {
    
     const [resultCount, setResultCount] =  useState(0); 
     const [ status, setStatus ] = useState(STATUS_IN_PROGRESS); 
 

     useEffect(() => {     
      
         const startRebaseObjects = async () => {
         const cnt  = await rebaseApi.postRebase_Objects('all'); 
        
         if (cnt === FETCH_DATA_ERROR) {
          setStatus(STATUS_FAILED); 
              
         } else {          
           setResultCount(cnt);       
           setStatus(STATUS_SUCCESSFUL); 
         }  
       };
 
       if(start){
        startRebaseObjects();  
       }
   
       
     }, [start]);//runs once
   
     return {         resultCount,    status    };

};



export { useRebase_Objects , useRebase_InsertAllObjects }